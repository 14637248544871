<template>
  <div class="settings-content-wrapper">
    <breadcrumbs :breadcrumbs="breadcrumbs"></breadcrumbs>
    <dashboard-navigation-group v-for="(group, index) in groups" v-bind:key="index" :group="group"></dashboard-navigation-group>
  </div>
</template>

<style>

</style>

<script>

import DashboardNavigationGroup from '../DashboardNavigationGroup.vue'
import Breadcrumbs from '@/components/Breadcrumbs.vue'

export default {
  name: 'reports-home',
  components: { DashboardNavigationGroup, Breadcrumbs },
  data () {
    return {
      breadcrumbs: [
        { name: 'Reports', route: null }
      ],
      groups: [
        {
          name: 'Reports',
          items: [
            { name: 'Income Report', icon: 'reports-income', route: 'reports-income', description: 'Report on your business earnings.' },
            { name: 'Sales Tax Report', icon: 'reports-sales-tax', route: 'reports-sales-taxes', description: 'Report on the sales taxes charged on your invoices.' }
          ]
        }
      ]
    }
  }
}

</script>
