<template>
  <div class="column is-one-third-tablet ">
    <div class="media clickable" @click="route">
      <div class="media-left">
        <figure class="image is-48x48">

          <!-- Reports Icons -->
          <img src="../assets/dashboard-navigation-icons/reports-income.svg" v-if="item.icon === 'reports-income'" :alt="item.name" />
          <img src="../assets/dashboard-navigation-icons/reports-sales-tax.svg" v-if="item.icon === 'reports-sales-tax'" :alt="item.name" />
          <img src="../assets/dashboard-navigation-icons/reports-bas.svg" v-if="item.icon === 'reports-bas'" :alt="item.name" />

          <!-- Settings Icons -->
          <img src="../assets/dashboard-navigation-icons/settings-business-details.svg" v-if="item.icon === 'settings-business-details'" :alt="item.name" />
          <img src="../assets/dashboard-navigation-icons/settings-subscription.svg" v-if="item.icon === 'settings-subscription'" :alt="item.name" />
          <img src="../assets/dashboard-navigation-icons/settings-categories.svg" v-if="item.icon === 'settings-categories'" :alt="item.name" />
          <img src="../assets/dashboard-navigation-icons/settings-change-password.svg" v-if="item.icon === 'settings-change-password'" :alt="item.name" />
          <img src="../assets/dashboard-navigation-icons/settings-invoice-settings.svg" v-if="item.icon === 'settings-invoice-settings'" :alt="item.name" />
          <img src="../assets/dashboard-navigation-icons/settings-online-payments.svg" v-if="item.icon === 'settings-online-payments'" :alt="item.name" />
          <img src="../assets/dashboard-navigation-icons/settings-opening-hours.svg" v-if="item.icon === 'settings-opening-hours'" :alt="item.name" />
          <img src="../assets/dashboard-navigation-icons/settings-services-offered.svg" v-if="item.icon === 'settings-services-offered'" :alt="item.name" />
          <img src="../assets/dashboard-navigation-icons/settings-products.svg" v-if="item.icon === 'settings-products'" :alt="item.name" />
          <img src="../assets/dashboard-navigation-icons/settings-theme.svg" v-if="item.icon === 'settings-theme'" :alt="item.name" />
          <img src="../assets/dashboard-navigation-icons/settings-tax-rates.svg" v-if="item.icon === 'settings-tax-rates'" :alt="item.name" />
          <img src="../assets/dashboard-navigation-icons/settings-user-accounts.svg" v-if="item.icon === 'settings-user-accounts'" :alt="item.name" />

        </figure>
      </div>
      <div class="media-content">
        <p class="title-item-menu">{{ item.name }}</p>
        <p class="subtitle-item-menu">{{ item.description }}</p>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>

@import '../scss/theme-default';

.media-left {
  padding-top: 5px;
}

.clickable {
  cursor: pointer;
}

.clickable:hover {
  color: $link-hover;
}

.title-item-menu {
  padding-top: 5px;
  font-size: 18px;
  font-weight: 600;
  padding-bottom: 0px;
  color: #326BFF;
}
.subtitle-item-menu {
  font-size: 14px;
  color: #7E8BB6;
  line-height: 18px;
  padding: 3px !important;
}

.media:hover {
  .subtitle-item-menu {
    color: #99AACC;
  }
}

</style>

<script>

export default {
  methods: {
    route () {
      this.$router.push({ name: this.item.route })
    }
  },
  name: 'dashboard-navigation-item',
  props: ['item']
}

</script>
