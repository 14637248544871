<template>
  <div class="setting-bottom-padding">
    <div class="settings-title"><h3>{{ group.name }}</h3></div>
    <div class="columns is-multiline">
      <dashboard-navigation-item v-for="(item, index) in group.items" v-bind:key="index" :item="item"></dashboard-navigation-item>
    </div>
  </div>
</template>

<style lang="scss" scoped>

.setting-bottom-padding {
  padding-bottom: 60px;
}

.settings-title {
  padding-bottom: 10px;
}

</style>

<script>

import DashboardNavigationItem from './DashboardNavigationItem.vue'

export default {
  name: 'dashboard-navigation-group',
  components: { DashboardNavigationItem },
  props: ['group']
}

</script>
