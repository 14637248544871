<template>
  <nav class="breadcrumb" aria-label="breadcrumbs">
    <ul>
      <li :class="{ 'is-active': (index === breadcrumbs.length - 1) }"
          v-for="(crumb, index) in breadcrumbs"
          v-bind:key="index"
          @click="route(crumb.route)"
      >
        <a v-if="crumb.route" @route="crumb.route">{{ crumb.name }}</a>
        <a v-else aria-current="page">{{ crumb.name }}</a>
      </li>
    </ul>
    <hr>
  </nav>
</template>

<style lang="scss" scoped>

</style>

<script>

export default {
  methods: {
    route (route) {
      if (route) {
        this.$router.push({ name: route })
      }
    }
  },
  name: 'breadcrumbs',
  props: ['breadcrumbs']
}

</script>
